import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierPanthere = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierPanthere {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Panthere/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Panthere Watches for Sale'}
      canonical={'/fine-watches/cartier/'}
      description={
        ' Grays & Sons are experts in buying and selling second hand Cartier Panthere Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/cat/cartier-panthere/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-panthere-header.png"
                alt="Pre-Owned Certified Used Cartier Panthere Header"
                aria-label="Used Cartier Panthere Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER PANTHERE</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER PANTHERE
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER PANTHERE WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              Gray and Sons is a real watch and jewelry store located in Surfside/Miami. We are
              across from Bal Harbour Shops. We have real watch and jewelry experts on-site, open
              six days a week, we are not a virtual store. Our customers say we are #1 in Miami for
              used watches. We buy and sell and have been proudly assisting for the past 42 years.
              We're sellers and buyers of pre-owned Cartier Panthere watches in Miami, Miami Beach,
              Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles ,
              Aventura, Fort Lauderdale and many more areas.
              <br />
              <br />
              Gray and Sons has been in business for 42 years, earning the trust of thousands of
              customers from across the world. It is a safe place to purchase or sell a certified,
              authentic Cartier watch, including the Cartier Panthère. In 1983, Cartier introduced
              the premier Panthère collection of quartz movement watches. These world renowned
              timepieces come adorned with diamonds on the bezels, precious stones, and countless
              combinations of glamorous yellow gold, white gold, and rose gold links and stainless
              steel on the bracelets. Get these Cartier watches on www.grayandsons.com. &ensp;
              <a href="/">grayandsons.com.</a> Sell these watches on &ensp;
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com.</a> These celebrated
              watches are exquisite embellishments for any modern woman or man and you can get them
              now at Gray and Sons in Surfside, Florida! The Panthère de Cartier wristwatch has
              stood the test of time with its superior quality and aesthetically precise
              proportions. The Panthère also makes a powerful statement in the world of high end,
              luxury jewelry. If you are searching for an iconic Cartier timepiece to compliment
              your collection, Gray and Sons has an extensive in stock inventory of certified,
              authentic, pre-owned Cartier watches. Try adding the Cartier Panthere to your
              collection.
              <br />
              <br />
              Customers consider Gray and Sons the #1 independent watch dealer and Cartier Panthère
              watch repair service center. Do you want to sell your Cartier Panthere for cash? We
              will buy your Cartier Panthère watch for cash! All of the Cartier watches in our
              inventory come with our exclusive in house one year warranty. Buy Cartier watches on
              grayandsons.com, or sell us your Cartier using our sales platform
              sellusyourjewelry.com. If you have any questions about our process or any of the
              luxury watches we offer, please contact a representative for further assistance. You
              can chat 24/7 with a real representative using our live chat option &ensp;
              <a href="/chat-with-viktoria">here.</a> <br />
              <br />
              Make a deal with our decision makers now, Rich and Vika. All in stock watches at Gray
              and Sons showroom as well as online at grayandsons.com have been recently serviced,
              freshly lubricated, time adjusted and fully detailed. Come by the real storefront,
              call or email us, real people, to work directly with any of our expertly trained, in
              house watch specialists. Put a smile on your loved ones faces and a piece of horologic
              history on their wrists. Our Swiss-trained master watchmakers vet every watch and can
              give any used Cartier watch a brand new life. From Cartier service repairs with
              complete overhaul to a simple battery replacement, or watch appraisal, you will not be
              disappointed with our expert customer service and unmatched attention to detail. Make
              an investment in the unmatched quality craftsmanship of Cartier. Let Gray and Sons
              help you celebrate your momentous occasions with the unrivaled gift of Panthère De
              Cartier.
              <br />
              <br />
              Not sure what to buy your wife, or mother for Mother’s Day? Buy her a Cartier Panthere
              watch from Gray and Sons. Some of the most popular Cartier Panthere reference numbers
              are W2PN0006, Small model in 2tone two tone with yellow gold and steel, WJPN0016, a
              medium model in yellow gold with diamonds, W2PN000, a medium model in yellow gold and
              stainless steel, W25029B6 Stainless steel and 18K Yellow Gold, W25033P5 in Stainless
              Steel, W25022B9 18K yellow gold, W25032P5 in stainless steel, W25054P5 in stainless
              steel, W25028b501 18k yellow gold, W25014B9 18k yellow gold, W25028B6 in stainless
              steel and yellow gold.
              <br />
              <br />
              Some other popular Cartier models are the Cartier Tank, Santos de Cartier, Pasha, and
              Ballon Bleu to name a few. Find all of these Cartier models and more at Gray and Sons.
              We also buy and sell Rolex, Omega, Patek Philippe, Chopard, Audemars Piguet and more.
              When you hear someone say, “Where should I go to talk to a real watch specialist?
              Where should I sell my watch for cash?” Tell them to look up Gray and Sons. We have
              something great for you. We offer certified authentic and certfied pre-owned watches.
              <br />
              <br />
              We are very excited to hear from you. Sign up for our free monthly catalog. Visit the
              store, call or visit our website at <a href="/">www.grayandsons.com</a>.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/filter/cat/cartier-panthere/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierPanthere
